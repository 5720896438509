import { useEffect } from 'react';

function Alert({ type, msg, showAlert }) {
  useEffect(() => {
    const timer = setTimeout(() => {
      showAlert();
    }, 3000);

    return () => clearTimeout(timer);
  }, [showAlert]);

  const alertClasses = `alert alert-${type}
  sm:text-lg`;

  return <p className={alertClasses}>{msg}</p>;
}

export default Alert;
