import emailjs from '@emailjs/browser';
import Button from '../ui/Button';
import { useState } from 'react';
import Alert from './Alert';

const Email = () => {
  const [emailAlert, setEmailAlert] = useState({
    show: false,
    type: '',
    msg: '',
  });

  const [isSending, setIsSending] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });

  function sendEmail(e) {
    e.preventDefault();

    setIsSending(true);

    emailjs
      .sendForm(
        'service_goqttfn',
        'template_80jj74g',
        e.target,
        'user_VOjAjHBHYyuc9oglCHwdz'
      )
      .then(res => {
        setEmailAlert({
          show: true,
          type: 'success',
          msg: 'Email sent successfully',
        });
        setFormData({
          name: '',
          email: '',
          message: '',
        });
        console.log(res);
      })
      .catch(err => {
        setEmailAlert({
          show: true,
          type: 'error',
          msg: 'Email failed to send.',
        });
        console.error(err);
      })
      .finally(() => {
        setIsSending(false);
      });
  }

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div
      className="bg-orange-600 h-[30rem]    max-w-xl mx-auto
      
    "
    >
      <h2
        className="text-xl mb-1
      xp:mb-2
      sm:text-2xl
      ms:-mt-4
      ll:mt-0
      xx:text-4xl
      rr:text-4xl
      gg:text-5xl"
        style={{ fontFamily: "'Macondo', cursive" }}
      >
        Email the Author
      </h2>
      <Alert
        type={emailAlert.type}
        msg={emailAlert.msg}
        showAlert={() => setEmailAlert({ show: false })}
      />
      <form
        onSubmit={sendEmail}
        className="w-[90vw] max-w-lg p-2 bg-orange-300 mx-auto h-[26rem]  border-amber-500 border-4 rounded-md
        sm:text-lg sm:h-[29.5rem]"
      >
        <div className="p-2 flex justify-center">
          <label htmlFor="name">Name:&nbsp;</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            className="rounded-lg indent-1 w-[80%] bg-orange-100"
            required
            minLength="4"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex justify-center p-2">
          <label htmlFor="email">Email:&nbsp;</label>
          <input
            type="text"
            name="email"
            id="email"
            placeholder="Email"
            className="rounded-lg indent-1 w-[80%] bg-orange-100"
            required
            value={formData.email}
            onChange={handleInputChange}
          />
        </div>
        <div className="flex justify-center p-2">
          <textarea
            name="message"
            id="message"
            cols="35"
            rows="10"
            className="rounded-md indent-1 bg-orange-100
            "
            placeholder="Write message here..."
            value={formData.message}
            onChange={handleInputChange}
          ></textarea>
        </div>

        <Button
          className="px-3 py-1 text-center rounded-lg shadow-lg   mt-2 shadow-orange-500 hover:scale-110 active:scale-100 cursor-pointer transition-all text-xl tracking-wider bg-orange-600 text-orange-300 font-semibold
          xp:py-2 xp:px-4"
          type="submit"
          disabled={isSending}
        >
          {isSending ? 'Sending...' : 'Send'}
        </Button>
      </form>
    </div>
  );
};

export default Email;
